import React from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import './scrollbar.scss';

interface Props {
	children: React.ReactNode;
}

const Scrollbar = ({ children }: Props) => {
	return (
		<SimpleBar autoHide={false} style={{ height: '100vh' }}>
			{children}
		</SimpleBar>
	);
};

export default Scrollbar;
