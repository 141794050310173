export const validateLatinExtended = (value: string) => {
	const regex = /^[a-zA-ZąĄčČęĘėĖįĮšŠųŲūŪžŽ\s]*$/;
	return regex.test(value);
};

export const validateEmail = (email: string) => {
	const rgx =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return rgx.test(email);
};
