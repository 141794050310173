import React, { useEffect, useState } from 'react';
import * as style from './layout.module.scss';
import Disclaimer from 'components/disclaimer/disclaimer';
import Header from 'components/header/header';
import Footer from 'components/footer/footer';
import SideContent from 'components/sideContent/sideContent';
import NewsSideContent from 'components/sideContent/newsSideContent';
import Scrollbar from 'components/scrollbar/scrollbar';
import useSessionModal from 'hooks/useSessionModal';
import newsRoutes from '../languages/pageTranslations/newsRoutes.json';

interface Props {
	children: React.ReactElement;
	originalPath: string;
	pageType: string;
}

const Layout = ({ children, originalPath }: Props) => {
	const { isVisible, hide } = useSessionModal();
	const isDarkLogo = originalPath !== '/';

	return (
		<>
			<div className={style.container}>
				<div className={style.mainContent}>
					<Scrollbar>
						<Header darkLogo={isDarkLogo} />
						{children}
						<Footer />
					</Scrollbar>
				</div>
				{Object.values(newsRoutes).includes(originalPath) ? (
					<NewsSideContent />
				) : (
					<SideContent originalPath={originalPath} />
				)}
				<Disclaimer isVisible={isVisible} hide={hide} />
			</div>
			<div id="modalBase" />
		</>
	);
};

export default Layout;
