import React from 'react';
import { createPortal } from 'react-dom';
import Modal from 'react-modal';
import * as style from './modal.module.scss';

type Props = {};

const ModalBase = ({ isVisible, hide, children, isDesktopMenu }: Props) => {
	const classes = {
		base: isDesktopMenu ? style.desktopMenuOverlay : style.overlay,
		afterOpen: style.afterOpen,
		beforeClose: style.beforeClose,
	};

	return (
		isVisible &&
		createPortal(
			<Modal
				closeTimeoutMS={2000}
				isOpen={isVisible}
				onRequestClose={hide}
				className={style.container}
				overlayClassName={classes}
				afterOpen={style.afterOpen}
				ariaHideApp={false}
			>
				{children}
			</Modal>,
			document.getElementById('modalBase')
		)
	);
};

export default ModalBase;
