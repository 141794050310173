// extracted by mini-css-extract-plugin
export var close = "contact-module--close--3d299";
export var contactFormWrapper = "contact-module--contactFormWrapper--f490a";
export var error = "contact-module--error--13038";
export var fomrContainer = "contact-module--fomrContainer--527b2";
export var footer = "contact-module--footer--70add";
export var form = "contact-module--form--fe4b3";
export var formError = "contact-module--formError--f16f5";
export var formErrorSecondary = "contact-module--formErrorSecondary--34c7a";
export var formField = "contact-module--formField--2d02d";
export var occupation = "contact-module--occupation--990cf";
export var personImg = "contact-module--personImg--68ed5";
export var personInfo = "contact-module--personInfo--4e90a";
export var send = "contact-module--send--3d087";
export var success = "contact-module--success--2f2d1";
export var wrapper = "contact-module--wrapper--860a0";