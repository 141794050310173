import React, { useContext } from 'react';
import * as style from './footer.module.scss';
import { StateContext } from '../../globalState';
import { Link } from 'gatsby';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import routes from 'languages/pageTranslations/routes.json';

const translations = defineMessages({
	ppbInformationTitle: {
		id: 'footer.title.ppbInformation',
		defaultMessage: 'PPB information',
	},
	contactsTitle: {
		id: 'footer.title.contacts',
		defaultMessage: 'Contacts',
	},
	worktime: {
		id: 'footer.workTime',
		defaultMessage: 'Monday to Friday from 8AM to 6PM (Vanuatu Time - UTC+11)',
	},
	headerEmail: {
		id: 'footer.header.email',
		defaultMessage: 'Email:',
	},
	email: {
		id: 'footer.email',
		defaultMessage: 'info@pacificprivatebank.com',
	},
	headerAddress: {
		id: 'footer.header.address',
		defaultMessage: 'Address:',
	},
	address: {
		id: 'footer.address',
		defaultMessage: '278 Kumul Highway, Govant Building, Port Vila, Vanuatu',
	},
	headerPhone: {
		id: 'footer.header.phone',
		defaultMessage: 'Phone:',
	},
	phone: {
		id: 'footer.phone',
		defaultMessage: '+678 24 500 / Fax: +678 24 501',
	},
	headerBic: {
		id: 'footer.header.bic',
		defaultMessage: 'Bank Identifier Code (SWIFT):',
	},
	bic: {
		id: 'footer.bic',
		defaultMessage: 'PPBLVUVU',
	},
	headerCompanyNr: {
		id: 'footer.header.companyNr',
		default: 'Company registration number: ',
	},
	companyNr: {
		id: 'footer.companyNr',
		defaultMessage: '5467',
	},
	headerFatca: {
		id: 'footer.header.fatca',
		defaultMessage: 'FATCA Participating Financial Institution:',
	},
	fatca: {
		id: 'footer.fatca',
		defaultMessage: 'GIIN No. IWIII2.99999.SL.548',
	},
	headerLei: {
		id: 'footer.header.lei',
		defaultMessage: 'Legal Entity Identifier (LEI):',
	},
	lei: {
		id: 'footer.lei',
		defaultMessage: '789000O8ECT10W21OO36',
	},
});

const Footer = () => {
	const { formatMessage, locale } = useIntl();
	const { store } = useContext(StateContext);
	const { footerContent } = store;
	return (
		<footer className={style.container}>
			<div className={style.wrapper}>
				{footerContent === 'default' && (
					<div className={style.disclaimer}>
						<FormattedMessage
							id="disclaimer.nb"
							defaultMessage="NB! Access to the information contained on this website may be restricted by laws and regulations applicable to the users residing in certain jurisdictions. Applicable legal requirements may prevent certain visitors from being solicited, offered certain services or financial products and engage in business with Pacific Private Bank. Please note that the financial services described in this website do not constitute an offer to transact business in any jurisdiction where such an offer would be considered unlawful, including the US, Canada, Japan, Australia, Switzerland, UK and British Isles or any of EEA country. The information provided on this website does not constitute an offer or solicitation to buy or sell any banking service, security, investment fund, investment advice or other product or service to anyone in any jurisdiction in which an offer or solicitation is not authorized or cannot legally be made or to any person to whom it is unlawful to make an offer or solicitation. If you choose to access this website, you do so at your own initiative and risk and are responsible for compliance with applicable laws, rules and regulations that may apply to you."
						/>
					</div>
				)}
				{footerContent !== 'default' && (
					<div>
						{footerContent === 'contacts' && (
							<div className={style.title}>
								{formatMessage(translations.contactsTitle)}
							</div>
						)}
						{footerContent === 'ppbInformation' && (
							<div className={style.title}>
								{formatMessage(translations.ppbInformationTitle)}
							</div>
						)}
						<div className={style.infoBlockFirst}>
							{footerContent === 'ppbInformation' && (
								<div className={style.bold}>
									{formatMessage(translations.worktime)}
								</div>
							)}
							<div>
								<b>{formatMessage(translations.headerEmail)}</b>
								{formatMessage(translations.email)}
							</div>
							<div>
								<b>{formatMessage(translations.headerAddress)}</b>
								{formatMessage(translations.address)}
							</div>
							<div>
								<b>{formatMessage(translations.headerPhone)}</b>
								{formatMessage(translations.phone)}
							</div>
						</div>
						<div className={style.infoBlockSecond}>
							<div>
								<b>{formatMessage(translations.headerBic)}</b>
								{formatMessage(translations.bic)}
							</div>
							<div>
								<b>{formatMessage(translations.headerCompanyNr)}</b>
								{formatMessage(translations.companyNr)}
							</div>
							<div>
								<b>{formatMessage(translations.headerFatca)}</b>
								{formatMessage(translations.fatca)}
							</div>
							<div>
								<b>{formatMessage(translations.headerLei)}</b>
								{formatMessage(translations.lei)}
							</div>
						</div>
					</div>
				)}
				<div className={style.footerBottom}>
					<span>
						<FormattedMessage
							id="footer.footnote"
							defaultMessage="© 2015-{year} Authorised and regulated by the Reserve Bank of Vanuatu |"
							values={{
								year: new Date().getFullYear(),
							}}
						/>
					</span>
					<Link to={routes[locale]["/privacy-policy/"]}>
						<FormattedMessage
							id="footer.footnote.privacyPolicy"
							defaultMessage=" Privacy Policy | "
						/>
					</Link>
					<a href="/Information-Security-Policy-PPB-20201015-01.pdf">
						<FormattedMessage
							id="footer.footnote.securityPolicy"
							defaultMessage="Information Security Policy | "
						/>
					</a>
					<Link to={routes[locale]["/bank-holidays/"]}>
						<FormattedMessage
							id="footer.footnote.bankHolidays"
							defaultMessage="Bank Holidays"
						/>
					</Link>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
