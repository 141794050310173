import React, { useState } from 'react';
import * as style from './disclaimer.module.scss';
import classNames from 'classnames';
import close from 'assets/img/close.png';
import { FormattedMessage } from 'react-intl';

interface Props {
	isVisible: boolean;
	hide: () => void;
}

const Disclaimer = ({ isVisible, hide }: Props) => {
	return (
		<div className={classNames(style.container, { [style.open]: isVisible })}>
			<div className={style.wrapper}>
				<img src={close} onClick={hide} className={style.close} />
				<p className={style.disclaimer}>
					<FormattedMessage
						id="disclaimer.nb"
						defaultMessage="NB! Access to the information contained on this website may be restricted by laws and regulations applicable to the users residing in certain jurisdictions. Applicable legal requirements may prevent certain visitors from being solicited, offered certain services or financial products and engage in business with Pacific Private Bank. Please note that the financial services described in this website do not constitute an offer to transact business in any jurisdiction where such an offer would be considered unlawful, including the US, Canada, Japan, Australia, Switzerland, UK and British Isles or any of EEA country. The information provided on this website does not constitute an offer or solicitation to buy or sell any banking service, security, investment fund, investment advice or other product or service to anyone in any jurisdiction in which an offer or solicitation is not authorized or cannot legally be made or to any person to whom it is unlawful to make an offer or solicitation. If you choose to access this website, you do so at your own initiative and risk and are responsible for compliance with applicable laws, rules and regulations that may apply to you."
					/>
				</p>
			</div>
		</div>
	);
};

export default Disclaimer;
