import React from 'react';
import * as style from './sideContent.module.scss';
import key from 'assets/img/key.png';
import quoteBubble from 'assets/img/quote-bubble.png';
import { useContext } from 'react';
import { StateContext } from '../../globalState';
import classNames from 'classnames';
import { ActionType } from 'enums/types';
import { Link } from 'gatsby';
import { FormattedMessage, useIntl } from 'react-intl';
import routes from 'languages/pageTranslations/routes.json';



const content = {
	'/pacific-private-bank-fee-and-charges-structure-changes/': {
		title: 'Pacific Private Bank Fee and Charges Structure Changes',
	},
	'/pacific-private-bank-welcomes-new-chief-commercial-officer/': {
		title: 'Pacific Private Bank Welcomes New Chief Commercial Officer',
	},
	'/ppb-presents-at-the-ict-days-2019/': {
		title: 'Pacific Private Bank presents at the ICT Days 2019',
	},
	'/vanuatu-joins-interpol/': {
		title: 'Vanuatu Joins International Criminal Police Organization',
	},
	'/viktorija-at-the-cams-conference-in-tokyo/': {
		title: 'Viktorija at the CAMS 10th Annual Conference in Tokyo',
	},
	'/viktorija-sadauskaite-certified-anti-money-laundering-specialist/': {
		title: 'Viktorija Sadauskaite – Certified Anti-Money Laundering Specialist',
	},
	'/vanuatu-receives-fatf-recognition/': {
		title: 'Vanuatu Receives Financial Action Task Force (FATF) Recognition',
	},
	'/pacific-private-bank-is-acquired-by-fintech-entrepreneurs/': {
		title: 'Pacific Private Bank is Acquired by FinTech Entrepreneurs',
	},
	'/information-common-reporting-standard-crs/': {
		title: 'Information about the Common Reporting Standard (CRS)',
	},
	'/pacific-private-bank-team-attended-sibos-2017/': {
		title: 'Pacific Private Bank Team Attended the Prestigious SIBOS 2017',
	},
	'/pacific-private-bank-welcomes-new-chief-operating-officer/': {
		title: 'Pacific Private Bank Welcomes New Chief Operating Officer',
	},
	'/pacific-private-bank-in-the-banking-conference-sibos-2016/': {
		title: 'Pacific Private Bank at Banking Conference SIBOS 2016',
	},
	'/new-improved-web-design/': {
		title: 'New and Improved Pacific Private Bank Website Design',
	},
	'/internet-banking-now-available-at-pacific-private-bank/': {
		title: 'Internet Banking Platform is Available at Pacific Private Bank',
	},
	'/toma-zarzeckyte-a-certified-anti-money-laundering-specialist/': {
		title: 'Toma Zaržeckytė – Certified Anti-Money Laundering Specialist',
	},
};

const NewsSideContent = () => {
	const { locale } = useIntl();
	const { store, dispatch } = useContext(StateContext);
	const { footerContent } = store;
	let path = '';
	if (typeof window !== 'undefined') {
		path = window.location.pathname
	}
	const handleClick = (content: string) => {
		if (content === footerContent) {
			dispatch({
				type: ActionType.SetFooterContent,
				payload: {
					footerContent: 'default',
				},
			});
		} else {
			dispatch({
				type: ActionType.SetFooterContent,
				payload: {
					footerContent: content,
				},
			});
		}
	};
	return (
		<div className={style.newsContainer}>
			<div className={style.wrapper}>
				<div className={style.key}>
					<span className={style.icoHolder}>
						<img src={key} />
					</span>
					<a href="https://ib.pacificprivatebank.com" target='_blank'>
						<FormattedMessage id="header.ibLink.title" defaultMessage="Internet Bank" />
					</a>
				</div>
				<div className={classNames(style.newsSideContentText, { [style.newsMargin]: path === '/news/' })}>
					{path === '/news/' &&
						<>
							<img src={quoteBubble} alt="Quote bubble" />
							<span className={style.blueText}>
								<FormattedMessage id="latestNews.text" defaultMessage="Latest News" />
							</span>
						</>
					}
					{
						Object.keys(content).includes(path) &&
						<>
							<Link to={routes[locale]['/news/']} className={style.newsLink}>
								<FormattedMessage id="latestNews.text" defaultMessage="Latest News" />
							</Link>
							<p className={style.whiteText}>
								{content[path].title}
							</p>
						</>
					}
				</div>
				<div className={style.sideContentLinks}>
					<span
						className={classNames({
							[style.selected]: footerContent === 'ppbInformation',
						})}
						onClick={() => handleClick('ppbInformation')}
					>
						<FormattedMessage
							id="cover.ppbInformation"
							defaultMessage="PPB Information"
						/>
					</span>
					<span
						className={classNames({
							[style.selected]: footerContent === 'contacts',
						})}
						onClick={() => handleClick('contacts')}
					>
						<FormattedMessage id="cover.contacts" defaultMessage="Contacts" />
					</span>
					<Link to={routes[locale]["/news/"]}>
						<FormattedMessage id="cover.latestNews" defaultMessage="Latest News" />
					</Link>
				</div>
			</div>
		</div>
	);
};

export default NewsSideContent;
