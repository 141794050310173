import { defineMessages } from 'react-intl';

export default defineMessages({
	navSolutions: {
		id: 'nav.solutions',
		defaultMessage: 'Solutions',
	},
	navWealthManagement: {
		id: 'nav.wealthManagement',
		defaultMessage: 'Wealth Management',
	},
	navDiscretionary: {
		id: 'nav.discretionary',
		defaultMessage: 'Discretionary',
	},
	navAdvisory: {
		id: 'nav.advisory',
		defaultMessage: 'Advisory',
	},
	navAssetManagement: {
		id: 'nav.assetManagament',
		defaultMessage: 'Asset Management',
	},
	navPortfolios: {
		id: 'nav.portfolios',
		defaultMessage: 'Tailor-made portfolios',
	},
	navBonds: {
		id: 'nav.bonds',
		defaultMessage: 'Bonds and equities',
	},
	navInvestments: {
		id: 'nav.investments',
		defaultMessage: 'Structured investments',
	},
	navFunds: {
		id: 'nav.funds',
		defaultMessage: 'Mutual funds',
	},
	navStrategy: {
		id: 'nav.strategy',
		defaultMessage: 'Strategy mandates',
	},
	navHedgeFunds: {
		id: 'nav.hedgeFunds',
		defaultMessage: 'Hedge funds, private equity and real estate funds',
	},
	navPrivateBanking: {
		id: 'nav.privateBanking',
		defaultMessage: 'Private Banking',
	},
	navAccountServices: {
		id: 'nav.accountServices',
		defaultMessage: 'Personalised account service',
	},
	navDeposits: {
		id: 'nav.deposits',
		defaultMessage: 'Savings and term deposits',
	},
	navTransfers: {
		id: 'nav.transfers',
		defaultMessage: 'International wire transfers',
	},
	navExchangeServices: {
		id: 'nav.exchangeServices',
		defaultMessage: 'Treasury and Foreign Exchange services',
	},
	navCardServices: {
		id: 'nav.cardServices',
		defaultMessage: 'Card services',
	},
	navMobileBanking: {
		id: 'nav.mobileBanking',
		defaultMessage: 'Online and Mobile banking',
	},
	navTradingServices: {
		id: 'nav.tradingServices',
		defaultMessage: 'Trading services',
	},
	navDemoAccount: {
		id: 'nav.demoAccount',
		defaultMessage: 'Open a Demo Account',
	},
	navAbout: {
		id: 'nav.about',
		defaultMessage: 'About the bank',
	},
	navAboutUs: {
		id: 'nav.aboutUs',
		defaultMessage: 'About us',
	},
	navPhilosophy: {
		id: 'nav.philosophy',
		defaultMessage: 'Our philosophy',
	},
	navTeam: {
		id: 'nav.team',
		defaultMessage: 'Our team',
	},
	navCompliance: {
		id: 'nav.compliance',
		defaultMessage: 'Compliance',
	},
	navDocuments: {
		id: 'nav.documents',
		defaultMessage: 'Corporate and compliance documents',
	},
	navContact: {
		id: 'nav.contact',
		defaultMessage: 'Contact-Us',
	},
	navSocialResponsibility: {
		id: 'nav.socialResponsibility',
		defaultMessage: 'Social Responsibility',
	},
});
