import React, { Dispatch, SetStateAction, useState } from 'react';
import close from 'assets/img/close.png';
import person from 'assets/img/Eimantas.jpg';
import ReCAPTCHA from 'react-google-recaptcha';
import * as style from './contact.module.scss';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { validateEmail, validateLatinExtended } from 'helpers/helpers';

const translations = defineMessages({
	fullNamePlaceholder: {
		id: 'contact.fullName.placeholder',
		defaultMessage: 'Your full name*',
	},
	emailPlaceholder: {
		id: 'contact.email.placeholder',
		defaultMessage: 'E-mail*',
	},
	subjectPlaceholder: {
		id: 'contact.subject.placeholder',
		defaultMessage: 'Subject',
	},
	messagePlaceholder: {
		id: 'contact.message.placeholder',
		defaultMessage: 'Message',
	},
	errorRequired: {
		id: 'contactError.required',
		defaultMessage: 'The field is required',
	},
	errorInvalidName: {
		id: 'contactError.invalid.fullName',
		defaultMessage: 'Full name is invalid',
	},
	errorLongName: {
		id: 'contactError.long.fullName',
		defaultMessage: 'Full name is too long',
	},
	errorLongEmail: {
		id: 'contactError.long.email',
		defaultMessage: 'Email is too long',
	},
	errorInvalidEmail: {
		id: 'contactError.invalidTitle',
		defaultMessage: 'Email is invalid',
	},
	error: {
		id: 'contactErrorMain',
		defaultMessage: 'Error occurred. Try again',
	},
	errorSecondary: {
		id: 'contactErrorSecondary',
		defaultMessage: 'One or more fields have an error. Please check and try again.'
	},
	success: {
		id: 'contact.success',
		defaultMessage: 'Thank you for your message. It has been sent.',
	},
});

interface State {
	fullName: string;
	fullNameError: string | null;
	email: string;
	emailError: string | null;
	subject: string;
	subjectError: string | null;
	message: string;
	messageError: string | null;
	sending: boolean;
	formError: string | null;
	formError2: string | null;
	formSuccess: string | null;
}

interface Result {
	msg: string;
}

interface Props {
	toggle: Dispatch<SetStateAction<boolean>>;
}

const PROJECT_NAME = 'pacificPrivateBank';
const recaptchaRef = React.createRef() as any;

const config = process.env.GATSBY_APP
	? require(`../../../config.${process.env.GATSBY_APP}.js`)
	: require(`../../../config.${process.env.NODE_ENV}.js`);

const INITIAL_STATE = {
	fullName: '',
	fullNameError: null,
	email: '',
	emailError: null,
	subject: '',
	subjectError: null,
	message: '',
	messageError: null,
	sending: false,
	formError: null,
	formError2: null,
	formSuccess: null,
};

const Contact = ({ toggle }: Props) => {
	const [formState, setFormState] = useState<State>(INITIAL_STATE);
	const { formatMessage } = useIntl();

	const handleInputChange = (
		event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>
	) => {
		const targetName = event.currentTarget.name;
		const newValue = event.currentTarget.value;

		return setFormState((prevState) => ({
			...prevState,
			[targetName]: newValue,
			fullNameError: null,
			subjectError: null,
			emailError: null,
			messageError: null,
			formSuccess: null,
			formError: null,
			formError2: null,
		}));
	};

	const validateForm = () => {
		const { fullName, email, subject, message } = formState;

		setFormState((prevState) => ({
			...prevState,
			fullNameError,
			emailError,
			subjectError,
			messageError,
		}));

		let fullNameError: string | null = null;
		let emailError: string | null = null;
		let subjectError: string | null = null;
		let messageError: string | null = null;

		if (!fullName) fullNameError = formatMessage(translations.errorRequired);
		if (fullName && !validateLatinExtended(fullName))
			fullNameError = formatMessage(translations.errorInvalidName);
		if (fullName && fullName.length > 128)
			fullNameError = formatMessage(translations.errorLongName);

		if (!email) emailError = formatMessage(translations.errorRequired);
		if (email && !validateEmail(email))
			emailError = formatMessage(translations.errorInvalidEmail);
		if (email && email.length > 128) emailError = formatMessage(translations.errorLongEmail);

		if (!subject) subjectError = formatMessage(translations.errorRequired);

		if (!message) messageError = formatMessage(translations.errorRequired);

		if (fullNameError || emailError || subjectError || messageError) {
			return setFormState((prevState) => ({
				...prevState,
				fullNameError,
				emailError,
				subjectError,
				messageError,
				formError2: formatMessage(translations.errorSecondary),
			}));
		}
		return handleSubmit();
	};

	const handleSubmit = async () => {
		if (
			typeof recaptchaRef.current !== 'undefined' &&
			recaptchaRef !== null &&
			recaptchaRef.current !== null
		) {
			recaptchaRef.current.execute().then((token: string) => {
				const reqBody = {
					env: PROJECT_NAME,
					fullName: formState.fullName,
					email: formState.email,
					subject: formState.subject,
					message: formState.message,
					token,
				};

				setFormState((prevState) => ({
					...prevState,
					sending: true,
				}));

				fetch(`${config.BASE_URL}/sendemail`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(reqBody),
				})
					.then((response: Body) => response.json())
					.then((result: Result) => {
						if (result.msg === 'failed') {
							setFormState((prevState) => ({
								...prevState,
								formError: formatMessage(translations.error),
								sending: false,
							}));
						}

						if (result.msg === 'success') {
							setFormState((prevState) => ({
								...prevState,
								formSuccess: formatMessage(translations.success),
								sending: false,
							}));
						}
					})
					.catch((error) => {
						return setFormState((prevState) => ({
							...prevState,
							formError: error ? formatMessage(translations.error) : null,
							sending: false,
						}));
					});
			});
		}
	};

	return (
		<div className={style.wrapper}>
			<img src={close} className={style.close} onClick={toggle} />
			<div className={style.personImg}>
				<img src={person} />
			</div>
			<div className={style.personInfo}>
				<span>
					<FormattedMessage id="contact.name" defaultMessage="Eimantas Kazlauskas" />
				</span>
				<span className={style.occupation}>
					<FormattedMessage id="contact.occupation" defaultMessage="Managing Director" />
				</span>
			</div>
			<div>
				<div>
					<div>
						<div className={style.contactFormWrapper}>
							<div className={style.form}>
								{formState.formSuccess && <div className={style.success}>{formState.formSuccess}</div>}
								{formState.formError && <div className={style.formError}>{formState.formError}</div>}
								{formState.formError2 && <div className={style.formErrorSecondary}>{formState.formError2}</div>}
								<div className={style.formField}>
									<input
										type="text"
										placeholder={formatMessage(
											translations.fullNamePlaceholder
										)}
										name="fullName"
										onChange={handleInputChange}
									/>
									{formState.fullNameError && (
										<span className={style.error}>
											{formState.fullNameError}
										</span>
									)}
								</div>
								<div className={style.formField}>
									<input
										type="email"
										placeholder={formatMessage(translations.emailPlaceholder)}
										name="email"
										onChange={handleInputChange}
									/>
									{formState.emailError && (
										<span className={style.error}>{formState.emailError}</span>
									)}
								</div>
								<div className={style.formField}>
									<input
										type="text"
										placeholder={formatMessage(translations.subjectPlaceholder)}
										name="subject"
										onChange={handleInputChange}
									/>
									{formState.subjectError && (
										<span className={style.error}>
											{formState.subjectError}
										</span>
									)}
								</div>
								<div className={style.formField}>
									<textarea
										cols={40}
										rows={10}
										placeholder={formatMessage(translations.messagePlaceholder)}
										name="message"
										onChange={handleInputChange}
									></textarea>
									{formState.messageError && (
										<span className={style.error}>
											{formState.messageError}
										</span>
									)}
								</div>
							</div>
							<div className={style.footer}>
								<FormattedMessage
									id="contact.tip"
									defaultMessage="All fields are required. We guarantee confidentiality."
								/>
							</div>
						</div>
						<button className={style.send} onClick={() => validateForm()}>
							<i />
							<span>
								<FormattedMessage id="contact.send" defaultMessage="Send" />
							</span>
						</button>
						<ReCAPTCHA
							ref={recaptchaRef}
							key={recaptchaRef}
							size="invisible"
							sitekey={config.RECAPTCHA_SITE_KEY}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
