import { useState } from 'react';

const useModal = () => {
	const [isVisible, setIsVisible] = useState(false);

	const toggle = () => {
		if (typeof window != 'undefined' && window.document) {
			document.body.style.overflow = isVisible ? 'initial' : 'hidden';
		}
		setIsVisible(!isVisible);
	};

	return {
		isVisible,
		toggle,
	};
};

export default useModal;
