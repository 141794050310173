import React, { useReducer, createContext, Dispatch } from 'react';
import { ActionType } from 'enums/types';

interface State {
	footerContent: string;
}

interface Action {
	type: ActionType;
	payload: State;
}

interface Props {
	children: React.ReactNode;
}

export const initialState: State = {
	footerContent: 'default',
};

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case ActionType.SetFooterContent:
			return { ...state, footerContent: action.payload.footerContent };
		default:
			return state;
	}
};

export const StateContext = createContext<{ store: State; dispatch: Dispatch<Action> }>({
	store: initialState,
	dispatch: () => null,
});

export const Provider = ({ children }: Props) => {
	const [store, dispatch] = useReducer<React.Reducer<State, Action>>(reducer, initialState);
	return <StateContext.Provider value={{ store, dispatch }}>{children}</StateContext.Provider>;
};
