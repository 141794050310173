import React, { useState } from 'react';
import * as style from './mobileNav.module.scss';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { useIntl, defineMessages } from 'react-intl';
import routes from 'languages/pageTranslations/routes.json';

interface Props {
	toggle: () => void;
	menuOpen: boolean;
	toggleContact: () => void;
}

const translations = defineMessages({
	navSolutions: {
		id: 'nav.solutions',
		defaultMessage: 'Solutions',
	},
	navWealthManagement: {
		id: 'nav.wealthManagement',
		defaultMessage: 'Wealth Management',
	},
	navAssetManagement: {
		id: 'nav.assetManagament',
		defaultMessage: 'Asset Management',
	},
	navPrivateBanking: {
		id: 'nav.privateBanking',
		defaultMessage: 'Private Banking',
	},
	navAbout: {
		id: 'nav.about',
		defaultMessage: 'About the bank',
	},
	navAboutUs: {
		id: 'nav.aboutUs',
		defaultMessage: 'About us',
	},
	navCompliance: {
		id: 'nav.compliance',
		defaultMessage: 'Compliance',
	},
	navContact: {
		id: 'nav.contact',
		defaultMessage: 'Contact-Us',
	},
	navSocialResponsibility: {
		id: 'nav.socialResponsibility',
		defaultMessage: 'Social Responsibility',
	},
});

const MobileNav = ({ menuOpen, toggle, toggleContact }: Props) => {
	const { formatMessage, locale } = useIntl();
	const [solutionsActive, setSolutionsActive] = useState(true);
	const [aboutActive, setAboutActive] = useState(false);

	const handleContactClick = () => {
		toggle();
		toggleContact()
	}

	return (
		<div className={classNames(style.accordion, { [style.open]: menuOpen })}>
			<div className={style.accordionItem}>
				<div
					className={style.accordionTitle}
					onClick={() => setSolutionsActive(!solutionsActive)}
				>
					<div>{formatMessage(translations.navSolutions)}</div>
				</div>
				{solutionsActive && (
					<div className={style.accordionContent}>
						<ul>
							<li>
								<Link to={routes[locale]['/wealth-management/']} onClick={toggle}>
									{formatMessage(translations.navWealthManagement)}
								</Link>
							</li>
							<li>
								<Link to={routes[locale]['/asset-management/']} onClick={toggle}>
									{formatMessage(translations.navAssetManagement)}
								</Link>
							</li>
							<li>
								<Link to={routes[locale]['/private-banking/']} onClick={toggle}>
									{formatMessage(translations.navPrivateBanking)}
								</Link>
							</li>
						</ul>
					</div>
				)}
			</div>
			<div className={style.accordionItem}>
				<div className={style.accordionTitle} onClick={() => setAboutActive(!aboutActive)}>
					<div>{formatMessage(translations.navAbout)}</div>
				</div>
				{aboutActive && (
					<div className={style.accordionContent}>
						<ul>
							<li>
								<Link to={routes[locale]['/compliance/']} onClick={toggle}>
									{formatMessage(translations.navCompliance)}
								</Link>
							</li>
							<li>
								<Link to={routes[locale]['/about-us/']} onClick={toggle}>
									{formatMessage(translations.navAboutUs)}
								</Link>
							</li>
							<li onClick={handleContactClick}>{formatMessage(translations.navContact)}</li>
						</ul>
					</div>
				)}
			</div>
			<div className={style.accordionItem}>
				<div className={style.accordionTitle}>
					<Link to={routes[locale]['/social-responsibility/']} onClick={toggle}>
						{formatMessage(translations.navSocialResponsibility)}
					</Link>
				</div>
			</div>
		</div>
	);
};

export default MobileNav;
