export enum ActionType {
	SetFooterContent = 'SET_FOOTER_CONTENT',
}

export enum ScreenType {
	Desktop = 'desktop',
	Tablet = 'tablet',
	Mobile = 'mobile',
}

export enum Sections {
	Solutions = 'solutions',
	AboutTheBank = 'aboutTheBank',
	SocialResponsibility = 'socialResponsibility',
}
