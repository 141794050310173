// extracted by mini-css-extract-plugin
export var blueText = "sideContent-module--blueText--169ce";
export var container = "sideContent-module--container--1181a";
export var icoHolder = "sideContent-module--icoHolder--44b5f";
export var key = "sideContent-module--key--c4c97";
export var newsContainer = "sideContent-module--newsContainer--5619a";
export var newsLink = "sideContent-module--newsLink--1c63b";
export var newsMargin = "sideContent-module--newsMargin--dba72";
export var newsSideContentText = "sideContent-module--newsSideContentText--8f574";
export var selected = "sideContent-module--selected--902c4";
export var sideContentBottom = "sideContent-module--sideContentBottom--413a5";
export var sideContentFull = "sideContent-module--sideContentFull--93338";
export var sideContentLinks = "sideContent-module--sideContentLinks--b6acc";
export var sideContentTitle = "sideContent-module--sideContentTitle--f4815";
export var sideContentTop = "sideContent-module--sideContentTop--57c3c";
export var whiteText = "sideContent-module--whiteText--03be3";
export var wrapper = "sideContent-module--wrapper--353d1";