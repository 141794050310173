import React from 'react';
import * as style from './sideContent.module.scss';
import sideContentImages from 'components/images/sideContentImages';
import { useContext } from 'react';
import { StateContext } from '../../globalState';
import classNames from 'classnames';
import { ActionType } from 'enums/types';
import { Link } from 'gatsby';
import { FormattedMessage, useIntl } from 'react-intl';
import routes from 'languages/pageTranslations/routes.json';

interface Props {
	originalPath: string;
}

const content = {
	'/about-us/': {
		title: 'About us',
		background: sideContentImages.waves,
	},
	'/social-responsibility/': {
		title: 'Social Responsibility',
		background: sideContentImages.socialResponsibility,
	},
	'/asset-management/': {
		title: 'Asset Management',
		background: sideContentImages.waves,
	},
	'/bank-holidays/': {
		title: 'Bank Holidays',
		background: sideContentImages.waves,
	},
	'/privacy-policy/': {
		title: 'Privacy Policy',
		background: sideContentImages.waves,
	},
	'/news/': {
		title: 'Latest News',
		background: sideContentImages.waves,
	},
	'/wealth-management/': {
		title: 'Wealth Management',
		background: sideContentImages.waves,
	},
	'/private-banking/': {
		title: 'Private Banking',
		background: sideContentImages.waves,
	},
	'/compliance/': {
		title: 'Compliance',
		background: sideContentImages.waves,
	},
	'/team/': {
		title: '',
		background: sideContentImages.sky,
	},
};

const SideContent = ({ originalPath }: Props) => {
	const isIndex = originalPath === '/';
	const { locale } = useIntl();
	const { store, dispatch } = useContext(StateContext);
	const { footerContent } = store;

	const handleClick = (content: string) => {
		if (content === footerContent) {
			dispatch({
				type: ActionType.SetFooterContent,
				payload: {
					footerContent: 'default',
				},
			});
		} else {
			dispatch({
				type: ActionType.SetFooterContent,
				payload: {
					footerContent: content,
				},
			});
		}
	};

	return (
		<>
			{isIndex && (
				<div className={style.container}>
					<div className={style.wrapper}>
						<div className={style.key}>
							<span className={style.icoHolder}>
								<img src={sideContentImages.key} />
							</span>
							<a href="https://ib.pacificprivatebank.com" target='_blank'>
								<FormattedMessage
									id="header.ibLink.title"
									defaultMessage="Internet Bank"
								/>
							</a>
						</div>
						<div className={style.sideContentTop}></div>
						<div className={style.sideContentBottom}></div>
						<div className={style.sideContentLinks}>
							<span
								className={classNames({
									[style.selected]: footerContent === 'ppbInformation',
								})}
								onClick={() => handleClick('ppbInformation')}
							>
								<FormattedMessage
									id="cover.ppbInformation"
									defaultMessage="PPB Information"
								/>
							</span>
							<span
								className={classNames({
									[style.selected]: footerContent === 'contacts',
								})}
								onClick={() => handleClick('contacts')}
							>
								<FormattedMessage id="cover.contacts" defaultMessage="Contacts" />
							</span>
							<Link to={routes[locale]["/news/"]}>
								<FormattedMessage
									id="cover.latestNews"
									defaultMessage="Latest News"
								/>
							</Link>
						</div>
					</div>
				</div>
			)}
			{!isIndex && (
				<div className={style.container}>
					<div className={style.wrapper}>
						<div className={style.key}>
							<span className={style.icoHolder}>
								<img src={sideContentImages.key} />
							</span>
							<a href="https://ib.pacificprivatebank.com" target='_blank'>
								<FormattedMessage
									id="header.ibLink.title"
									defaultMessage="Internet Bank"
								/>
							</a>
						</div>
						{Object.hasOwn(content, originalPath) ? (
							<div
								className={style.sideContentFull}
								style={{
									backgroundImage: `url(${content[originalPath].background})`,
								}}
							>
								<div className={style.sideContentTitle}>
									{content[originalPath].title}
								</div>
							</div>
						) : (
							<div
								className={style.sideContentFull}
								style={{
									backgroundImage: `url(${sideContentImages.waves})`,
								}}
							></div>
						)}
						<div className={style.sideContentLinks}>
							<span
								className={classNames({
									[style.selected]: footerContent === 'ppbInformation',
								})}
								onClick={() => handleClick('ppbInformation')}
							>
								<FormattedMessage
									id="cover.ppbInformation"
									defaultMessage="PPB Information"
								/>
							</span>
							<span
								className={classNames({
									[style.selected]: footerContent === 'contacts',
								})}
								onClick={() => handleClick('contacts')}
							>
								<FormattedMessage id="cover.contacts" defaultMessage="Contacts" />
							</span>
							<Link to={routes[locale]["/news/"]}>
								<FormattedMessage
									id="cover.latestNews"
									defaultMessage="Latest News"
								/>
							</Link>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SideContent;
