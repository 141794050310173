const React = require('react');
const Layout = require('./src/layout/layout').default;
const Provider = require('./src/globalState').Provider;
const { IntlProvider } = require('react-intl');
const en = require('./src/languages/en.json');
const messages = {
	en,
};
export const wrapRootElement = ({ element }) => (
	<Provider>
		<IntlProvider locale="en" messages={messages.en}>
			{element}
		</IntlProvider>
	</Provider>
);

export const wrapPageElement = ({ element, props }) => {
	const { originalPath } = props.pageContext;
	return <Layout originalPath={originalPath}>{element}</Layout>;
};

export const shouldUpdateScroll = () => {
	document.getElementById('header').scrollIntoView();
	return false;
};
