import { useEffect, useState } from 'react';

const useSessionModal = () => {
	const [isVisible, setIsVisible] = useState(false);

	const hide = () => {
		if (typeof window !== 'undefined') sessionStorage.setItem('disclaimer', '1');
		setIsVisible(false);
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setIsVisible(sessionStorage.getItem('disclaimer') !== '1');
		}
	});
	return { isVisible, hide };
};

export default useSessionModal;
