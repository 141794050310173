import React, { useEffect, useState } from 'react';
import * as style from './header.module.scss';
import logoLight from 'assets/img/logo-light.png';
import logoDark from 'assets/img/logo-dark.png';
import key from 'assets/img/key.png';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { ScreenType } from '../../enums/types';
import useModal from 'hooks/useModal';
import ModalBase from 'components/modal/modalBase';
import Menu from 'components/menu/menu';
import useWindowWidth from 'hooks/useWindowWidth';
import Contact from 'components/contact/contact';
import routes from 'languages/pageTranslations/routes.json';

interface Props {
	darkLogo?: boolean;
}

const translations = defineMessages({
	logoAlt: {
		id: 'logoAlt',
		defaultMessage: 'Pacific Private Bank logo',
	},
	keyAlt: {
		id: 'keyAlt',
		defaultMessage: 'Key icon',
	},
});

const Header = ({ darkLogo }: Props) => {
	const [menuType, setMenuType] = useState<ScreenType | undefined>(ScreenType.Mobile);
	const { formatMessage, locale } = useIntl();
	const { isVisible, toggle } = useModal();
	const toggleContact = useModal()

	useWindowWidth();

	const getScreenType = () => {
		if (typeof window !== 'undefined') {
			let isMobileSize = window.innerWidth < 768;
			let isTabletSize = window.innerWidth > 768 && window.innerWidth < 1024;

			if (isMobileSize) return ScreenType.Mobile;
			if (isTabletSize) return ScreenType.Tablet;
			if (window.innerWidth > 1023) return ScreenType.Desktop;
		}
	};

	const updateSize = () => {
		const screentype = getScreenType();
		if (screentype !== menuType) {
			setMenuType(screentype);
		}
	};

	useEffect(() => {
		window.onresize = updateSize();
	});

	return (
		<>
			<header id="header" className={style.header}>
				<Link to={routes[locale]["/"]}>
					<img
						src={darkLogo ? logoDark : logoLight}
						className={style.logo}
						alt={formatMessage(translations.logoAlt)}
					/>
				</Link>
				{menuType === ScreenType.Desktop ? (
					<ul className={style.menu}>
						<li className={style.menuItem}>
							<span onClick={toggle}>
								<FormattedMessage
									id="header.option.solutions"
									defaultMessage="Solutions"
								/>
							</span>
						</li>
						<li className={style.menuItem}>
							<Link to={routes[locale]["/about-us/"]}>
								<FormattedMessage
									id="header.option.aboutTheBank"
									defaultMessage="About the Bank"
								/>
							</Link>
						</li>
						<li className={style.menuItem}>
							<Link to={routes[locale]["/social-responsibility/"]}>
								<FormattedMessage
									id="header.option.socialResponsibility"
									defaultMessage="Social Responsibility"
								/>
							</Link>
						</li>
					</ul>
				) : (
					<div className={style.key}>
						<span className={style.icoHolder}>
							<img src={key} alt={formatMessage(translations.keyAlt)} />
						</span>
						<a href="https://ib.pacificprivatebank.com" target='_blank'>
							<FormattedMessage
								id="header.ibLink.title"
								defaultMessage="Internet Bank"
							/>
						</a>
						<div className={style.hamHolder}>
							<div
								className={classNames(style.hamburger, { [style.open]: isVisible })}
								onClick={toggle}
							>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
							</div>
						</div>
					</div>
				)}
			</header>
			<ModalBase isVisible={isVisible} hide={toggle} isDesktopMenu={true}>
				<Menu menuType={menuType} toggle={toggle} menuOpen={isVisible} toggleContact={toggleContact.toggle} />
			</ModalBase>
			<ModalBase isVisible={toggleContact.isVisible} hide={toggleContact.toggle}>
				<Contact toggle={toggleContact.toggle} />
			</ModalBase>
		</>
	);
};

export default Header;
