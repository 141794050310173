import key from 'assets/img/key.png';
import waves from 'assets/img/waves.jpg';
import sky from 'assets/img/sky.jpg';
import socialResponsibility from 'assets/img/social-responsibility.jpg';

const sideContentImages = {
	key,
	waves,
	sky,
	socialResponsibility,
};

export default sideContentImages;
